
// Autogenerated
// -------------
// gulpfile.ts/wasm.ts --> generateBuildInfo

export const Build = {
    version: "0.72.0 (9d95650ce8e9eb9633ef5cec3fb91cb1)",
    buildSeed: 1631176127038,
    "wdosbox.wasm": {
        "size": 1463621,
        "gzSize": 491837
    },
    "wdosbox.js": {
        "size": 107939,
        "gzSize": 27716
    },
    "wlibzip.wasm": {
        "size": 112343,
        "gzSize": 54127
    },
    "wlibzip.js": {
        "size": 80579,
        "gzSize": 20779
    }
,
};
